var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "orderTest-container" },
    [
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          attrs: { stripe: "", data: _vm.list, border: "" },
        },
        [
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "140px",
              label: "备注类型",
              prop: "name",
            },
          }),
          _c("el-table-column", {
            attrs: {
              align: "center",
              width: "auto",
              label: "备注内容",
              prop: "mark_content",
            },
          }),
          _c("el-table-column", {
            attrs: { align: "center", width: "110", label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var $index = ref.$index
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text" },
                        nativeOn: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.editRow($index, row)
                          },
                        },
                      },
                      [_vm._v(" 编辑 ")]
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            modal: false,
            "close-on-click-modal": false,
            title: "自定义备注",
            width: "450px",
            top: "15vh",
            visible: _vm.showDialog,
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            close: _vm.close,
          },
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading2,
                  expression: "loading2",
                },
              ],
              staticClass: "wrapper",
            },
            [
              _c("div", { staticClass: "style" }, [
                _vm._v(" 备注类型: "),
                _c("span", [_vm._v(_vm._s(_vm.listDetail.name))]),
              ]),
              _c(
                "div",
                { staticClass: "info" },
                [
                  _c("el-input", {
                    attrs: {
                      type: "textarea",
                      rows: 7,
                      placeholder: "请输入内容",
                    },
                    model: {
                      value: _vm.listDetail.mark_content,
                      callback: function ($$v) {
                        _vm.$set(_vm.listDetail, "mark_content", $$v)
                      },
                      expression: "listDetail.mark_content",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSave } },
                [_vm._v("保存")]
              ),
              _c("el-button", { on: { click: _vm.close } }, [_vm._v("取 消")]),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }