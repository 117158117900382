import request from '@/utils/request'

export function getListData(data) {
  return request({
    url: '/baseAdmin/goods-mark/index',
    method: 'post',
    data,
  })
}

// 备注详情
export function getListDetail(data) {
  return request({
    url: '/baseAdmin/goods-mark/view',
    method: 'post',
    data,
  })
}

// 更新备注
export function updateList(data) {
  return request({
    url: '/baseAdmin/goods-mark/update',
    method: 'post',
    data,
  })
}
