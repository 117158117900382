<template>
  <div class="orderTest-container">
    <el-table v-loading="loading" stripe :data="list" border>
      <el-table-column
        align="center"
        width="140px"
        label="备注类型"
        prop="name"
      ></el-table-column>
      <el-table-column
        align="center"
        width="auto"
        label="备注内容"
        prop="mark_content"
      ></el-table-column>
      <el-table-column align="center" width="110" label="操作">
        <template #default="{ $index, row }">
          <el-button type="text" @click.native.prevent="editRow($index, row)">
            编辑
          </el-button>
          <!-- <el-button type="text" @click.native.prevent="deleteRow($index, row)">
            删除
          </el-button> -->
        </template>
      </el-table-column>
    </el-table>
    <el-dialog
      :modal="false"
      :close-on-click-modal="false"
      title="自定义备注"
      width="450px"
      top="15vh"
      :visible.sync="showDialog"
      @close="close"
    >
      <div v-loading="loading2" class="wrapper">
        <div class="style">
          备注类型:
          <span>{{ listDetail.name }}</span>
        </div>
        <div class="info">
          <el-input
            v-model="listDetail.mark_content"
            type="textarea"
            :rows="7"
            placeholder="请输入内容"
          ></el-input>
        </div>
      </div>
      <!-- 底部按钮 -->
      <div slot="footer" class="dialog-footer" style="text-align: right">
        <el-button type="primary" @click="handleSave">保存</el-button>
        <el-button @click="close">取 消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
  import { getListData, getListDetail, updateList } from '@/api/goodsRemark'
  export default {
    name: 'GoodsRemark',
    components: {},
    data() {
      return {
        showDialog: false,
        loading: true,
        loading2: true,
        textarea: '',
        list: [],
        listDetail: {
          type_id: 0,
          name: '',
          mark_content: '',
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (!val) {
          this.listDetail.name = ''
          this.listDetail.mark_content = ''
        }
      },
    },
    created() {
      this.fetchData()
    },
    methods: {
      async fetchData() {
        this.loading = true
        let { data, msg, code } = await getListData()

        if (code == 200) {
          let totalCount = data.count
          let list = data.rows
          this.list = list
        }
        this.loading = false
      },
      close() {
        this.showDialog = false
      },
      editRow(index, row) {
        console.log('编辑')
        console.log(index, row)
        this.listDetail.type_id = row.type_id
        this.listDetail.name = row.name
        getListDetail({ type_id: row.type_id }).then((res) => {
          this.loading2 = true
          if (res.code == 200) {
            console.log(res)
            this.listDetail.mark_content = res.data
          }
          this.loading2 = false
        })
        this.showDialog = true
      },
      handleSave() {
        updateList(this.listDetail).then((res) => {
          if (res.code == 200) {
            this.$message.success('保存成功')
            this.fetchData()
            this.showDialog = false
          }
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .style {
    margin-bottom: 20px;
  }
</style>
